

document.addEventListener("DOMContentLoaded", function () {

  // Dark Mode / Light Mode logic
  const modeSwitchers = document.querySelectorAll("#modeSwitcher");

  // Function to set the theme
  function setTheme(mode) {
    console.log(document.querySelectorAll('.default-arrow-class'))
    if (mode === "dark") {
      document.body.classList.add("dark-mode");
      document.body.classList.remove("light-mode");
      const darkVideo = document.querySelectorAll(".bg-video-dark");
      const lightVideo = document.querySelectorAll(".bg-video-light");
      darkVideo.forEach((element) => {
        element.setAttribute("style", "display: block;");
      });
      lightVideo.forEach((element) => {
        element.setAttribute("style", "display: none;");
      });
    } else {
      document.body.classList.add("light-mode");
      document.body.classList.remove("dark-mode");
    }
  }

  // Get the current mode from local storage
  const currentMode = localStorage.getItem("theme") || "dark";
  setTheme(currentMode);

 if(currentMode === 'light') {
  updateToLightMode();
 }
 

  modeSwitchers.forEach((modeSwitcher) => {
    modeSwitcher.addEventListener("click", () => {
      // Toggle the mode
      const newMode = document.body.classList.contains("dark-mode")
        ? "light"
        : "dark";
      setTheme(newMode);
      localStorage.setItem("theme", newMode);

      // Toggle mode source
      const modeIcons = document.querySelectorAll(".mode-icon");
      modeIcons.forEach((icon) => {
        icon.classList.toggle("light-mode");
      })
      // Toggle homepage
      document.querySelector(".homepage").classList.toggle("light-mode");
      document.querySelector(".homepage-mobile").classList.toggle("light-mode");
      document.querySelectorAll(".navigation-link").forEach((element) => {
        element.classList.toggle("light-mode");
      });

      // Toggle logo source
      const logoIcon = document.querySelectorAll(".logo-icon");
      logoIcon.forEach((icon) => {
        icon.classList.toggle("light-mode");
      });

      // Toggle video source
      const darkVideo = document.querySelectorAll(".bg-video-dark");
      const lightVideo = document.querySelectorAll(".bg-video-light");
      darkVideo.forEach((element) => {
        if(newMode === 'light') {
          element.setAttribute("style", "display: none;");
        } 
        if(newMode === 'dark') {
          element.setAttribute("style", "display: block;");
        }
      });
      lightVideo.forEach((element) => {
        if(newMode === 'light') {
          element.setAttribute("style", "display: block;");
        } 
        if(newMode === 'dark') {
          element.setAttribute("style", "display: none;");
        }
      });

      // Toggle App and Join Our community button
      const buttons = document.querySelectorAll(".appBtn");
      buttons.forEach((button) => {
        button.classList.toggle("light-mode");
      });

      // Toggle Unlock your longevity text
      const unlockYourLongevity = document.querySelectorAll(
        ".unlock-your-longevity"
      );
      const unlockYourLongevity1 = document.querySelectorAll(
        ".unlock-your-longevity1"
      );
      unlockYourLongevity1.forEach((element) => {
        element.classList.toggle("light-mode");
      });
      unlockYourLongevity.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      // Toggle content-text text
      const tiredOfGoing = document.querySelectorAll(".content-text");
      tiredOfGoing.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      const darkInputs = document.querySelectorAll(".subscribe-email");
      darkInputs.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      // Toggle white-text text
      const whiteText = document.querySelectorAll(".white-text");
      whiteText.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      // Toggle white buttons
      const whiteButtons = document.querySelectorAll(".whiteBtn");
      whiteButtons.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      // Toggle banners
      const banners = document.querySelectorAll(".banners");
      const banners1 = document.querySelectorAll(".banners1");
      banners.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      banners1.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      // Toggle feature cards
      const featureCards = document.querySelectorAll(".features-card-alt");
      featureCards.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      const featuresCardsMobile = document.querySelectorAll(
        ".features-card-alt4"
      );
      featuresCardsMobile.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      // Toggle community and newsletter images
      const community = document.querySelectorAll(".community");
      const newsletter = document.querySelectorAll(".newsletter");
      const newsletter1 = document.querySelectorAll(".newsletter1");

      community.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      newsletter.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      newsletter1.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      // Toggle faq  background
      const faqs = document.querySelectorAll(".faq1");
      const faqs6 = document.querySelectorAll(".faq6");
      faqs6.forEach((element) => {
        element.classList.toggle("light-mode");
      });
      faqs.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      // TOggle footer background
      const footer = document.querySelector(".footer2");
      const footer4 = document.querySelector(".footer4");
      footer.classList.toggle("light-mode");
      footer4.classList.toggle("light-mode");

      // Toggle content-text-background
      const contentTextBackground = document.querySelectorAll(
        ".content-text-background"
      );
      contentTextBackground.forEach((element) => {
        element.classList.toggle("light-mode");
      });
      // Toggle lightgreen text to darkgreen
      const lightgreenText = document.querySelectorAll(".light-green-text");
      lightgreenText.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      // Toggle social media icons
      const twitterIcons = document.querySelectorAll(".twitter-icon");
      twitterIcons.forEach((icon) => {
          icon.classList.toggle("light-mode");
      });

      const instagramIcons = document.querySelectorAll(".instagram-icon");
      instagramIcons.forEach((icon) => {
        icon.classList.toggle("light-mode");
      });
      const linkedinIcons = document.querySelectorAll(".linkedin-icon");
      linkedinIcons.forEach((icon) => {
        icon.classList.toggle("light-mode");
      });
      const facebookIcons = document.querySelectorAll(".facebook-icon");
      facebookIcons.forEach((icon) => {
        icon.classList.toggle("light-mode");
      });

      // Toggle footer bottom highlight
      const footerBottomHighlight = document.querySelectorAll(
        ".footer-bottom-highlight"
      );
      footerBottomHighlight.forEach((element) => {
        element.classList.toggle("light-mode");
      });

      // Toggle arrow image
     
    });
  });

  // Function to apply light mode to all elements
  function updateToLightMode() {
    const elementsToToggle = [
      ".homepage",
      ".homepage-mobile",
      ".navigation-link",
      ".appBtn",
      ".unlock-your-longevity",
      ".unlock-your-longevity1",
      ".content-text",
      ".subscribe-email",
      ".white-text",
      ".whiteBtn",
      ".banners",
      ".banners1",
      ".features-card-alt",
      ".features-card-alt4",
      ".community",
      ".newsletter",
      ".newsletter1",
      ".faq1",
      ".faq6",
      ".footer2",
      ".footer4",
      ".content-text-background",
      ".light-green-text",
      ".footer-bottom-highlight",
    ];

    elementsToToggle.forEach((selector) => {
      document.querySelectorAll(selector).forEach((element) => {
        element.classList.add("light-mode");
      });
    });

    // Toggle mode icon source
    const modeIcons = document.querySelectorAll(".mode-icon");
    modeIcons.forEach((icon) => {
      icon.classList.toggle("light-mode");
    })

    // Toggle logo source
    const logoIcon = document.querySelectorAll(".logo-icon");
    logoIcon.forEach((icon) => {
      icon.classList.toggle("light-mode");
    })

    // Toggle video source
    const darkVideo = document.querySelectorAll(".bg-video-dark");
    const lightVideo = document.querySelectorAll(".bg-video-light");

    darkVideo.forEach((element) => {
      element.setAttribute("style", "display: none;");
    });
    lightVideo.forEach((element) => {
      element.setAttribute("style", "display: block;");
    });

    // Toggle social media icons
    const twitterIcons = document.querySelectorAll(".twitter-icon");
    twitterIcons.forEach((icon) => {
        icon.classList.toggle("light-mode");
    });

    const instagramIcons = document.querySelectorAll(".instagram-icon");
    instagramIcons.forEach((icon) => {
      icon.classList.toggle("light-mode");
    });

    const linkedinIcons = document.querySelectorAll(".linkedin-icon");
    linkedinIcons.forEach((icon) => {
      icon.classList.toggle("light-mode");
    });
    const facebookIcons = document.querySelectorAll(".facebook-icon");
    facebookIcons.forEach((icon) => {
      icon.classList.toggle("light-mode");
    });

    
  
 
  }


  // FAQ logic
  window.toggleFaq = function (id) {
    const faq = document.getElementById(id);
    let content = faq.querySelector(".expanded");
    if (!content) {
      content = faq.querySelector(".collapsed");
    }
    const icon = faq.querySelector(".faq-icon");

    if (icon.classList.contains("faq-icon-collapsed")) {
      content.classList.remove("collapsed");
      content.classList.add("expanded");
      icon.classList.remove("faq-icon-collapsed");
      icon.classList.add("faq-icon-expanded");
    } else if (icon.classList.contains("faq-icon-expanded")) {
      content.classList.remove("expanded");
      content.classList.add("collapsed");
      icon.classList.remove("faq-icon-expanded");
      icon.classList.add("faq-icon-collapsed");
    }
  };

  // Testimonials slider logic (desktop)
  const sliderDesktop = function () {
    const slides = document.querySelectorAll(".slide");
    const btnLeft = document.querySelector(".slider__btn--left");
    const btnRight = document.querySelector(".slider__btn--right");

    if (!slides.length || !btnLeft || !btnRight) return;

    let curSlide = 0;
    const maxSlide = slides.length;

    const goToSlide = function (slide) {
      slides.forEach(
        (s, i) => (s.style.transform = `translateX(${100 * (i - slide)}%)`)
      );
    };

    const nextSlide = function () {
      curSlide = curSlide === maxSlide - 1 ? 0 : curSlide + 1;
      goToSlide(curSlide);
    };

    const prevSlide = function () {
      curSlide = curSlide === 0 ? maxSlide - 1 : curSlide - 1;
      goToSlide(curSlide);
    };

    const init = function () {
      goToSlide(0);
    };
    init();

    btnRight.addEventListener("click", nextSlide);
    btnLeft.addEventListener("click", prevSlide);

    document.addEventListener("keydown", function (e) {
      if (e.key === "ArrowLeft") prevSlide();
      if (e.key === "ArrowRight") nextSlide();
    });
  };

  sliderDesktop();

  // Testimonials slider logic (desktop)
  const sliderMobile = function () {
    const slides = document.querySelectorAll(".slide-mobile");

    if (!slides.length) return;

    let curSlide = 0;
    const maxSlide = slides.length;

    const goToSlide = function (slide) {
      slides.forEach(
        (s, i) => (s.style.transform = `translateX(${100 * (i - slide)}%)`)
      );
    };

    const nextSlide = function () {
      curSlide = curSlide === maxSlide - 1 ? 0 : curSlide + 1;
      goToSlide(curSlide);
    };

    const prevSlide = function () {
      curSlide = curSlide === 0 ? maxSlide - 1 : curSlide - 1;
      goToSlide(curSlide);
    };

    const init = function () {
      goToSlide(0);
    };
    init();

    // Touch events for mobile
    let startX = 0;
    let endX = 0;

    document.addEventListener("touchstart", function (e) {
      startX = e.touches[0].clientX;
    });

    document.addEventListener("touchmove", function (e) {
      endX = e.touches[0].clientX;
    });

    document.addEventListener("touchend", function (e) {
      if (startX > endX + 50) {
        nextSlide();
      } else if (startX < endX - 50) {
        prevSlide();
      }
    });
  };

  sliderMobile();

  // Update the year dynamically
  const yearSpans = document.querySelectorAll("#current-year");
  yearSpans.forEach((span) => {
    span.textContent = new Date().getFullYear();
  });

  // Add hover effect to arrow buttons
  const imgs = document.querySelectorAll(".arrow-buttons-icon");

  imgs.forEach((img) => {
    img.classList.add("default-arrow-class");
    img.addEventListener("mouseover", function () {
      img.classList.add("hidden");
      setTimeout(() => {
        img.classList.add("hover-arrow-class");
        img.classList.remove("default-arrow-class");
        img.classList.remove("hidden");
      }, 100);
    });

    img.addEventListener("mouseout", function () {
      img.classList.add("hidden");
      setTimeout(() => {
        img.classList.add("default-arrow-class");
        img.classList.remove("hover-arrow-class");
        img.classList.remove("hidden");
      }, 150);
    });
  });

  // Email subscription
  const forms = document.querySelectorAll("#email-subscription-form");

  forms.forEach((form) => {
    form.addEventListener("submit", async (e) => {
      e.preventDefault();

      const emailInput = form.querySelector(".subscribe-email");
      const loader = form.querySelector(".loader");
      const icon = form.querySelector(".arrow-buttons-icon");

      loader.style.display = "block";
      icon.style.display = "none";

      const apiEndpoint = "https://service.longevity.ac/newsletter/subscribe";
      const data = {
        email: emailInput.value,
      };

      try {
        const response = await fetch(apiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        const responseJSON = await response.json();

        const toast = document.querySelector("#toast");

        const showToast = (message, type) => {
          toast.classList.add(type);
          toast.style.display = "block";
          toast.textContent = message;
          emailInput.value = "";
          setTimeout(() => {
            toast.style.display = "none";
            toast.classList.remove("info-toast");
            toast.classList.remove("error-toast");
            toast.classList.remove("success-toast");
            toast.textContent = "";
          }, 3000);
        };

        if (responseJSON.response.status === "SUBSCRIBED") {
          console.log("Successfully subscribed");
          showToast(
            "You have successfully subscribed to our newsletter!",
            "success-toast"
          );

          // Push event to data layer for GTM
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "email_subscription_success",
            form_id: form.id,
            email: data.email,
          });
        } else if (
          responseJSON?.response?.error?.code ===
          "MEMBER_EXISTS_WITH_EMAIL_ADDRESS"
        ) {
          showToast("You are already subscribed!", "info-toast");
        } else {
          console.error("Failed to submit email", responseJSON);
          showToast("Failed to subscribe. Please try again.", "error-toast");
        }

        loader.style.display = "none";
        icon.style.display = "block";
      } catch (error) {
        console.error("Error:", error);
        loader.style.display = "none";
        icon.style.display = "block";
      }
    });
  });
});
